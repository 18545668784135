import React from "react";
import PropTypes from "prop-types";

const workflowsToSkip = [
  "AL-AMCC-NAR",
  "AL-AMCC-AR",
  "AL-AMCC-CR",
  "AL-AMCC-NCR",
  "AL-AMCC-PDR",
  "AL-AMCC-NPR",
  "AL-AMCC-PCCR",
  "AL-AMCC-PPCR",
  "AL-AMCC-PLU",
  "AL-AMCC-AU",
  "AL-AMCC-CU",
  "AL-AMCC-PHYU",
  "AL-AMCC-BLS",
  "AL-AMCC-PLR",
  "AL-AMCC-PHYR",
];

export default function ReviewPageBanner(props) {
  const { applicationType } = props;
  return (
    <span>
      Please review the application for accuracy and completeness. If you have
      any items marked with a red X, your application will not be accepted.
      {!workflowsToSkip.includes(applicationType) && (
        <span> Please review these items to ensure accuracy</span>
      )}
      <br />
      <span style={{ paddingLeft: "20px" }}>
        WARNING: Once your application is submitted, it cannot be modified.
        Please make sure your application is final and complete before
        submitting.
      </span>
    </span>
  );
}

ReviewPageBanner.propTypes = {
  applicationType: PropTypes.string.isRequired,
};
