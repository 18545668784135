export const getMaxCount = (formData = {}) => {
  const primaryVal = formData.qnPatRecRecommendation;
  const secondaryVal = formData.qnPatLifeExpectancyLess6M;
  if (secondaryVal === "Yes") return null;
  if (primaryVal === "Yes" && (!secondaryVal || secondaryVal === "No"))
    return 75;
  return 50;
};

export const handleMaxCountValidation = (props) => {
  const { value, getValues } = props;
  const maxCount = getMaxCount(getValues());
  if (maxCount === null) return undefined;
  return (
    (maxCount &&
      value &&
      Number(value) > maxCount &&
      `maximum value must be ${maxCount}`) ||
    undefined
  );
};
