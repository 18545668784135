import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function HelpDialogContent() {
  const { navConfig } = useSelector((state) => state.navConfig);
  const { phoneContact, supportContact } = navConfig.help;
  return (
    <Box sx={{ mb: "3em" }}>
      <Typography>
        You can view training material and find answers to Frequently Asked
        Questions (FAQs){" "}
        <a
          target="_blank"
          href="https://al.accessgov.com/amccmain/Forms/Page/amccmain/amcc-faq/"
          rel="noreferrer"
        >
          here.
        </a>
      </Typography>
      <Typography>
        For up-to-date information on the Alabama Medical Cannabis Program
        visit:{" "}
        <a target="_blank" href="https://amcc.alabama.gov/" rel="noreferrer">
          https://amcc.alabama.gov/
        </a>
        .
      </Typography>
      <Typography>
        For policy related questions regarding your application, please contact
        the Alabama Medical Cannabis Commission at{" "}
        <a href="mailto:applications@amcc.alabama.gov">
          applications@amcc.alabama.gov
        </a>
      </Typography>
      <Typography>
        For technical support and payment questions, Please contact NIC Alabama
        at&nbsp;
        <a href={`mailto:${supportContact}`} target="_top">
          {supportContact}
        </a>
        {phoneContact && (
          <>
            <span> or </span>
            <a href={`tel:${phoneContact}`}>{phoneContact}</a>{" "}
          </>
        )}
        . Thank you!
      </Typography>
    </Box>
  );
}

export default HelpDialogContent;
