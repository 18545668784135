import React from "react";
import Typography from "@mui/material/Typography";

function GettingStartedContent() {
  return (
    <>
      <Typography>
        <strong>
          {`Welcome to Alabama's Medical Cannabis registration and licensing
      system powered by NIC Licensing Solutions.`}
        </strong>
      </Typography>
      <Typography>
        From this platform, cannabis business applicants can apply, upload
        documents, submit payments, and manage applications. Applications in
        process are saved and can be finished later. Instructions for completing
        and submitting applications are available
        <a
          target="_blank"
          href="https://al.accessgov.com/amccmain/Forms/Page/amccmain/amcc-faq/"
          rel="noreferrer"
        >
          {" "}
          here.
        </a>
      </Typography>
      <Typography>
        If you need additional help, please click on the
        <a
          target="_blank"
          href="https://al.accessgov.com/amccmain/Forms/Page/amccmain/amcc-faq/"
          rel="noreferrer"
        >
          {" "}
          Help/FAQ{" "}
        </a>{" "}
        icon so we may better suit your needs.
      </Typography>
      <Typography>
        Please note that while the application requirements will remain the same
        under the rules, the user experience may differ slightly over time. This
        is because Alabama will continuously improve the application portal by
        incorporating actual user experience feedback to add additional guidance
        where necessary or fix unforeseen technical problems.
      </Typography>
    </>
  );
}
export default GettingStartedContent;
